import "./Project.scss";
import "./flickity.css";

import Flickity from 'react-flickity-component';
import React, { createRef } from 'react';
import { NavLink } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';

class ProjectMedia extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = createRef();
    }

    componentDidMount() {
        this.playOrPauseVideo();
    }

    componentDidUpdate() {
        this.playOrPauseVideo();
    }

    playOrPauseVideo() {
        if (this.videoRef.current != null) {
            if (this.props.isActive) {
                this.videoRef.current.play();
            } else {
                this.videoRef.current.pause();
            }
        }
    }

    render() {
        if (this.props.media.mediaType === "image") {
            return (<div className="CarouselMediaItem"
                        style={{ backgroundImage:`url(${this.props.media.mediaSrc})` }}></div>);
        } else if (this.props.media.mediaType === "video") {
            return (<video ref={this.videoRef} className="CarouselMediaItem"
                        src={this.props.media.mediaSrc}
                        poster={this.props.media.mediaPreview}
                        controls loop muted></video>);
        }
    }
}

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
    }

    componentDidMount() {
        this.flickity.on('settle', () => {
            this.setState({ activeIndex: this.flickity.selectedIndex });
        });
    }

    render() {
        return (
            <div>
                <Flickity flickityRef={c => this.flickity = c} fullScreen={true}>
                    {
                        this.props.project.contentMedia.map((media, index) => (
                            <div ref={this.mediaRef} key={index} className="CarouselItem">
                                <div className="CarouselItemBackground"
                                    style={{ backgroundImage:`url(${media.mediaBackground})`}}></div>
                                <ProjectMedia 
                                    media={media}
                                    isActive={this.state.activeIndex === index}
                                />
                            </div>
                        ))
                    }
                </Flickity>
            </div>
        )
    }
}

function Project(props) {    
    return (
        <section className="Project">
            <h1>
                <NavLink className="MenuItem BackNavLink" to="/projects">
                    <div className="MenuItemIcon">
                        <IoMdArrowBack />
                    </div>
                </NavLink>
                {props.project.title}
            </h1>
            <div className="ProjectContent">
                <p>{props.project.contentContext}</p>
                <Carousel project={props.project}/>
                {
                    props.project.contentDescription.map((item, index) => (
                        <p key={index}>{item}</p>
                    ))
                }
            </div>
        </section>
    );
}

export default Project;