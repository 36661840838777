import "./Projects.scss";
import { NavLink } from "react-router-dom";
import ProjectsData from "./ProjectsData.json";

function Projects() {
    return (
        <section className="Projects">
            <h1>Projects</h1>
            <div className="ProjectsList">
            {
                ProjectsData.map(project => (
                    <NavLink to={project.url} key={project.url} className="ProjectItem">
                        <div className="ProjectItemPreview">
                            <div className="ProjectItemPreviewIcon" style={{ backgroundImage:`url(${project.titleMediaSrc})` }}></div>
                        </div>
                        <div>
                            <h2>{project.title}</h2>
                            <p>{project.titleDescription}</p>
                        </div>
                    </NavLink>
                ))
            }
            </div>
        </section>
    );
}

export default Projects;