import "./Menu.scss";

import { NavLink } from "react-router-dom";

import { RiHomeFill } from 'react-icons/ri';
import { BsFillGridFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';

function Menu(props) {
    return (
      <div className="Menu">
        <NavLink to="/" className="MenuItem">
          <div className="MenuItemIcon">
            <RiHomeFill />
          </div>
        </NavLink>
        <NavLink to="/projects" className={`MenuItem ${props.location.pathname.includes("project") ? "active" : ""}`}>
          <div className="MenuItemIcon">
            <BsFillGridFill/>
          </div>
        </NavLink>
        <NavLink to="/contact" className="MenuItem">
          <div className="MenuItemIcon">
            <MdEmail />
          </div>
        </NavLink>
      </div>
    );
}

export default Menu;
