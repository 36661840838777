import './App.scss';
import React from 'react';

import { Routes, Route, useLocation } from "react-router-dom";

import Menu from './Menu.js';
import Home from './Home.js';
import Projects from './Projects.js';
import Contact from './Contact.js';
import Project from './Project.js';

import ProjectsData from "./ProjectsData.json";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/projects" element={<Projects />}/>
        <Route path="/contact" element={<Contact />}/>
        {
          ProjectsData.map(project => (
            <Route path={project.url} key={project.url} element={<Project project={project}/>}/>
          ))
        }
      </Routes>
      <Menu location={location} />
    </div>
  );
}

export default App;
