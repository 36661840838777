import "./Contact.scss";
import { MdEmail } from 'react-icons/md';

function Contact() {
    return (
        <section className="Contact">
            <div className="ContactLogo">
                <div className="ContactLogoIcon">
                    <MdEmail />
                </div>
            </div>
            <h1>Contact</h1>
            <p>
                Interested to hire me or talk about a project?
                I would love to have a chat
            </p>
            <p>Email me at <a href="mailto:florianezh@outlook.com">florianezh@outlook.com</a> 
            &nbsp; or connect on <a href="https://www.linkedin.com/in/florianezh/">Linkedin</a></p>
        </section>
    );
}

export default Contact;