import './Home.scss';
import { NavLink } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";

function Home () {
    return (
      <section className="Home">
        <img className="LogoImage" src="./florianezh_logo.png"/>
        <p className="LogoText">Floriane.Zh</p>
        <p>
        I am a Software Engineer with experience in Graphics Programming for games, and I would love
        to bring my abilities and passion to your team.
        </p>
        <NavLink to="/projects" className="ProjectNavLink">
          <div>View projects &nbsp;</div>
          <HiArrowNarrowRight />
        </NavLink>
    </section>
    )
}

export default Home;